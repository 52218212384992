import React, { useState, useEffect } from "react";

const ImageCrossfade = ({
  images = [],
  imageMaxWidth = "800px",
  interval = 2500,
  transitionDuration = 500,
  className = "",
}: {
  images: string[];
  imageMaxWidth: string;
  interval?: number;
  transitionDuration?: number;
  className?: string;
}) => {
  const [indices, setIndices] = useState({
    current: 0,
    next: 1,
    upcoming: 2,
  });
  const [transitionState, setTransitionState] = useState("idle"); // 'idle' | 'transitioning'

  useEffect(() => {
    if (images.length <= 1) return;

    const timer = setInterval(() => {
      setTransitionState("transitioning");

      setTimeout(() => {
        setIndices((prev) => ({
          current: (prev.current + 1) % images.length,
          next: (prev.next + 1) % images.length,
          upcoming: (prev.upcoming + 1) % images.length,
        }));
        setTransitionState("idle");
      }, transitionDuration);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval, transitionDuration]);

  const getOpacity = (position: string) => {
    switch (position) {
      case "current":
        return transitionState === "idle" ? 1 : 0;
      case "next":
        return transitionState === "idle" ? 0 : 1;
      case "upcoming":
        return 0;
      default:
        return 0;
    }
  };

  if (images.length === 0) return null;
  if (images.length === 1) {
    return (
      <div className={`relative overflow-hidden ${className}`}>
        <img
          src={images[0]}
          alt="Single"
          className="w-full h-full object-cover"
        />
      </div>
    );
  }

  return (
    <div className={`relative overflow-hidden ${className}`}>
      {/* Current Image */}
      <img
        src={images[indices.current]}
        alt={`Slide ${indices.current + 1}`}
        className={`absolute object-cover transition-opacity duration-${transitionDuration}`}
        style={{
          opacity: getOpacity("current"),
          height: "auto",
          maxWidth: imageMaxWidth,
        }}
      />
    </div>
  );
};

export default ImageCrossfade;
