import Footer from "./components/footer/footer";
import Hero from "./components/hero/hero";
import Social from "./components/social/Social";
import { Helmet } from "react-helmet";
import ImageCrossfade from "./components/utils/FadingImages";

function App() {
  return (
    <div>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://use.typekit.net/ueb5zfy.css"
        ></link>
        <script src="/tailwind/main.js"></script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Hero />
      <Social />

      <div className="pb-10 px-5 w-full items-center justify-center flex-col gap-20 mx-auto hidden md:flex">
        <ImageCrossfade
          images={[
            "/use-case/overwhelmed.png",
            "/use-case/blocked.png",
            "/use-case/stuck.png",
          ]}
          imageMaxWidth="600px"
          interval={3000}
          transitionDuration={0}
          className="w-full h-full object-cover min-h-[400px] flex items-center justify-center mt-22"
        />
        <div className="flex flex-col items-center justify-center w-full px-4">
          <h2 className="header-main-color text-3xl font-bold text-center">
            You cannot afford to wait for perfect data.
          </h2>
          <h2 className="header-sub-color text-2xl italic mt-2 text-center">
            And neither can humanity.
          </h2>
          <p className="mt-5 text-xl max-w-4xl leading-8 text-left px-5">
            If you wait on "perfect data", you will not only{" "}
            <span className="font-bold underline-orange">
              miss your 2040 targets
            </span>
            , you will achieve a{" "}
            <span className="font-bold underline-orange">
              lower cumulative impact
            </span>
            . Those responsible for establishing eco-design data flows at big
            pharma companies estimate it will take at least 5 years to have
            enough data from suppliers to begin making decisions.
          </p>
          <div className="w-full max-w-4xl px-4 mt-15">
            <img
              src="/perfect-data/chart.svg"
              alt="data-chart"
              className="w-full"
            />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-10 w-full gap-7">
          <div>
            <h2 className="header-main-color text-3xl font-bold ">
              Start making decisions{" "}
              <span className="underline-orange">today</span>.
            </h2>
            <h2 className="header-sub-color text-2xl italic mt-2">
              No data flows or clean notebooks needed.
            </h2>
          </div>
          <div className="w-full flex justify-center items-center">
            <img src="/landing/showcase.svg" alt="showcase" />
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-10 ">
          <h2 className="header-main-color text-3xl font-bold ">
            Designed closely with the pharma industry.
          </h2>
          <h2 className="header-sub-color text-2xl italic mt-2">
            For the pharma industry.
          </h2>
          <p className="mt-5 text-xl max-w-[800px] leading-8 px-5">
            <span className="font-bold">
              The pharmaceutical industry is complex, with many stakeholders
              involved, including regulatory
            </span>{" "}
            which is why we believe the key to success is partnering with
            companies in the industry to design the best possible solution for
            all involved.
          </p>
          <a href="https://calendar.notion.so/meet/kamikrista/wk1qz4ga2">
            <button className="rounded mt-5 bg-[#EE5A39] px-4 py-2 text-lg text-white hover:bg-[#D94A2D] md:px-8 md:py-4 md:text-xl font-semibold shadow-lg">
              Partner with us
            </button>
          </a>
          <div className="w-full mt-10 flex flex-col gap-5 max-w-[1000px] mx-auto px-2">
            <div className="flex flex-row gap-5 items-center justify-center">
              <div className="w-1/2">
                <img src="/landing/ten23-partner.svg" alt="ten23" />
              </div>
              <div className="w-1/2 flex flex-col gap-7">
                <p className="text-xl leading-8">
                  “... we are extremely proud to partner with Elio, to support
                  the creation of what we see as a{" "}
                  <span className="text-orange font-semibold">
                    game-changing, innovative, solution{" "}
                  </span>
                  for the pharmaceutical industry.”
                </p>
                <div>
                  <div className="text-lg font-bold header-main-color">
                    Alissa Monk
                  </div>
                  <div className="text-lg header-main-color">
                    Sustainability Co-lead at ten23 health
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-5 items-center justify-center">
              <div className="w-1/2">
                <img src="/landing/cytiva-partner.svg" alt="cytiva" />
              </div>
              <div className="w-1/2 flex flex-col gap-7">
                <p className="text-xl leading-8">
                  “We teamed up with <u>Elio.Earth</u> to explore how their
                  AI-powered software could help us implement sustainability
                  into our innovation projects.{" "}
                  <span className="text-orange font-semibold">
                    AI-mazing things are on the horizon
                  </span>
                  ."
                </p>
                <div>
                  <div className="text-lg font-bold header-main-color">
                    Karl Britt
                  </div>
                  <div className="text-lg header-main-color">
                    VP R&D, Sustainable Innovation at Cytiva
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-10 max-w-[1000px] mx-auto mb-20">
          <h2 className="header-main-color text-3xl font-bold ">
            Elevating industry collaboration.
          </h2>
          <h2 className="header-sub-color text-2xl italic mt-2">
            Beyond Elio, for all.
          </h2>
          <p className="mt-5 text-xl max-w-[800px] leading-8  px-5">
            <b>Elio initiated the Pharma Eco-design Consortium</b>, a
            pre-competitive incubator for pre-competitive eco-design solutions,
            with a focus on software and data. It came from a realisation that
            our data sharing problem with pharmaceutical suppliers was part of a
            much bigger data access management issue.
          </p>

          <div className="grid grid-cols-6 lg:grid-cols-5 w-full my-10 gap-10 max-w-[600px] mx-auto lg:max-w-none">
            <div className="flex flex-col items-center justify-start gap-2 col-span-2 lg:col-span-1">
              <div className="header-sub-color text-lg font-semibold">
                PHARMA CHAIR
              </div>
              <img
                src="/consortium/ten23_Logo_Animated_ani_einzel 1.png"
                alt="pharma-chair"
              />
            </div>
            <div className="flex flex-col items-center justify-start gap-2 col-span-2 lg:col-span-1">
              <div className="header-sub-color text-lg font-semibold">
                SOLUTION CHAIR
              </div>
              <img
                src="/consortium/EL-Logo-Text_Black@3x 2.png"
                alt="solution-chair"
              />
            </div>
            <div className="flex flex-col items-center justify-start gap-2 col-span-2 lg:col-span-1 h-full">
              <div className="header-sub-color text-lg font-semibold">
                WORK GR. CHAIR
              </div>
              <img src="/consortium/cytiva.png" alt="work-chair" />
            </div>
            <div className="col-span-1 lg:hidden "></div>
            <div className="flex flex-col items-center justify-start gap-2 col-span-2 lg:col-span-1">
              <div className="header-sub-color text-lg font-semibold">
                SOLUTION MBR.
              </div>
              <img
                src="/consortium/my-green-lab-logo-full-color 2.png"
                alt="solution-mbr"
              />
            </div>
            <div className="flex flex-col items-center justify-start gap-2 col-span-2 lg:col-span-1">
              <div className="header-sub-color text-lg font-semibold">
                SCALE PARTNER
              </div>
              <img src="/consortium/cphi.png" alt="cphi" />
            </div>
            <div className="col-span-1 lg:hidden "></div>
          </div>

          <div className="flex flex-row items-center justify-center gap-10">
            <div className="w-full">
              <img src="/consortium/zoom-call.png" alt="zoom-call" />
            </div>
            <div className="w-full flex flex-col gap-7">
              <p className="text-xl leading-8">
                “The Consortium is a unique industry incubator that combines the
                technical abilities of solution members like Elio with deep
                industry expertise of members like Cytiva.{" "}
                <span className="text-orange font-semibold">
                  This group is uniquely positioned to build solutions
                  accelerating eco-design
                </span>
                , which requires a collaborative approach across the value
                chain.”
              </p>
              <div>
                <div className="text-lg font-bold header-main-color">
                  Karl Britt
                </div>
                <div className="text-lg header-main-color">
                  VP R&D, Sustainable Innovation at Cytiva
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
